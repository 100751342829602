import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import twitter from '../assets/twitter-.png';
import teleg from '../assets/telegram.png';
import dexview from '../assets/dexview.png';
import dexToolse from '../assets/dexToolse.png';
import coingecko from '../assets/coingeko.png';
import coinmarket from '../assets/coinmarketcap.png';
import unisawp from '../assets/uniswap.png';
import base from '../assets/base.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}

const dexS = () => {
    window.open("https://www.dexview.com/");
}

const coin = () => {
    window.open("https://www.coingecko.com/");
}

const coinM = () => {
    window.open("https://coinmarketcap.com/");
}

const dexT = () => {
    window.open("https://www.coinbase.com/");
}

const baseL = () => {
    window.open("#");
}

const uniswapL = () => {
    window.open("https://uniswap.org/");
}

class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">

                    <div class="conT4">OUR TOP PARTNERS</div>

                    <div class="partnerDiv">
                        <img onClick={dexT} src={dexToolse} />
                        <img onClick={coin} src={coingecko} />
                        <img onClick={coinM} src={coinmarket} />
                    </div>

                    <div class="partnerDiv">
                        <img onClick={dexS} src={dexview} />
                        <img onClick={baseL} src={base} />
                        <img onClick={uniswapL} src={unisawp} />
                    </div>

                    <p class="punkSubH">Welcome to Basemew, the meme coin inspired by “BATMAN”! Emerging from Crypto City on the Base blockchain, Basemew blends meme culture with solid crypto economics. Join our community to experience humor, hope, and financial empowerment. Be part of the adventure and shape Basemew's destiny. Let's soar to the moon together! 🚀</p>

                    <div class="glitch-wrapper">
                        <div class="glitch" data-text="BASEMEW" onClick={() => window.location.href = '/'}><span className='hl2-2'>BASEMEW</span></div>
                    </div>

                    <div class="icons">
                        <img onClick={tweet} src={twitter} />
                        <img onClick={tg} src={teleg} />
                    </div>

                    <div class="copyright">Copyright © 2024 Basemew. All Rights Reserved</div>


                </div>



            </div>
        )
    }
}

export default Footer;

