/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import twitter from '../assets/twitter-.png';
import teleg from '../assets/telegram.png';
import '../App.css';
import Story from "../components/story";
import Ticker from '../components/ticker';
import Footer from '../components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logoPic from '../assets/logo.png';
import About from '../components/about';
import Benefits from '../components/benefits';
import Ticker2 from '../components/ticker2';
import Ticker3 from '../components/ticker3';
import Ticker4 from '../components/ticker4';
import Ticker5 from '../components/ticker5';
import Tokenomics from '../components/tokenomics';
import { base } from 'wagmi/chains';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}

const Home = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont">
					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = 'presale'}/></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = 'presale'}/></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					<div class="headers">

						<div class="h1">

							<div class="connect">
								<div ><Link activeClass="" id="fontSize" to="intro" spy={true} smooth={true} duration={550}>INTRODUCTION</Link></div>
								<div ><Link activeClass="" id="fontSize" to="token" spy={true} smooth={true} duration={550}>TOKENOMICS</Link></div>
								<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>DISTRIBUTION</Link></div>
							</div>

							<div class="logoDiv"><img src={logoPic} onClick={() => window.location.href = 'presale'}></img></div>
							<div class="logoDivMob"><img src={logoPic}  onClick={() => window.location.href = 'presale'}/></div>
							<div class="right">

								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
								</div>

								<div class="connect2">
								<button class="connectBtn" onClick={() => window.location.href = 'presale'}>PRESALE</button>
								</div>

							</div>

						</div>

					</div>

					<Link to="gallery" spy={true} smooth={true} duration={550}>


						{_navbarOpen > 0 ?
							(<div class="littleNav">
								<div ><Link activeClass="" id="fontSize" to="intro" spy={true} smooth={true} duration={550}>INTRODUCTION</Link></div>
								<div ><Link activeClass="" id="fontSize" to="token" spy={true} smooth={true} duration={550}>TOKENOMICS</Link></div>
								<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>DISTRIBUTION</Link></div>
								<div id="fontSize" onClick={() => window.location.href = 'presale'}>PRESALE</div>

								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
								</div>

							</div>) : null}
					</Link>

					<div className='introMain'>
						<div class="video-container">
							<video src="http://basemewassets.pages.dev/imgs/video.mp4"  onClick={() => window.location.href = 'presale'} autoPlay loop playsInline muted></video>
						</div>
						<p className='txtMain'>
							<div className='introMainDiv1' id="title"><span className='title_text'><span className='hl2-2'>Basemew</span></span></div>
							<div className='title_text_tagline'>INSPIRED BY BATMAN</div>
						</p>

						<Link activeClass="" id="fontSize" to="token" spy={true} smooth={true} duration={550}>
							<div className='presaleSection'>
								<div class="glitch-wrapper3">
									<div class="glitch3" data-text="Presale is live now">Presale is live now</div>
								</div>

								<center>
									<span class="pulse"></span>
								</center>
							</div>
						</Link>

					</div>

				</div>

				<Ticker3 />

				<Element name="intro">
					<About />
				</Element>

				<Ticker />

				<Element name="token">
					<Tokenomics />
				</Element>

				<Ticker2 />

				<Element name="story">
					<Story />
				</Element>

				<Ticker5 />

				<Benefits />

				<Ticker4 />

				<Footer />

			</div >
		</div >
	)

}
export default Home;
