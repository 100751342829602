import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const token = () => {
    window.open("#");
}



class Tokenomics extends Component {

    render() {
        return (

            <div class="boxWrap2StoryB">
                <div class="conT2"><span className='hl2-1'>BASEMEW TOKENOMICS</span> <br /><span class="hl2">OVERVIEW</span></div>

                <div class="about">
                    <div class="storyCon">
                        <div class="conT"><span className='hl3'>TOKEN</span></div>
                        <div class="tokenomicsCon">Token Name - Basemew</div>
                        <div class="tokenomicsCon">Symbol - BAM</div>
                        <div class="tokenomicsCon">Decimal - 18</div>

                        <div class="tokenomicsCon">
                            Contract address - <span class="tokenLink">0xfb74Fd8B4C93C328f1a4C53C178866ba0bECbCF1</span>
                        </div>

                        <div class="tokenomicsCon">Total Supply - 100 Billion of BAM</div>

                        <div class="tokenomicsCon">Blockchain - Base</div>
                    </div>


                    <div class="about3">

                        <div class="storyCon--2">
                            <div class="conT"><span className='hl3'>BASEMEW PRESALE</span></div>

                            <p>HARD CAP: 80 ETH/WETH</p>
                            <p>SHORT CAP: 5 ETH/WETH</p>
                            <p>PRICE PER $BAM TOKEN: 0,000000005 ETH/WETH</p>
                            <p>MAX PURCHASE: 4 ETH/WETH</p>
                            <p>MIN PURCHASE: 0,02 ETH/WETH</p>

                            <button className='btnPresale' onClick={() => window.location.href = 'presale'}>JOIN PRESALE >></button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Tokenomics;

