/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { useCall, mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, http, parseEther, webSocket } from 'viem';
import twitter from '../assets/twitter-.png';
import teleg from '../assets/telegram.png';
import '../App.css';
import Footer from '../components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logoPic from '../assets/logo.png';
import home from '../assets/home-button.png';
import { base } from 'wagmi/chains';
import Ticker3 from '../components/ticker3';
import { parseUnits } from 'ethers';
import check from '../assets/check.png';
import balloon from '../assets/oops.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}

let ABIToken = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "blacklistedArray",
				"type": "address[]"
			}
		],
		"name": "addBlacklisted",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "routerArray",
				"type": "address[]"
			}
		],
		"name": "addRouterAddresses",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "taxExemptedArray",
				"type": "address[]"
			}
		],
		"name": "addTaxExemptedAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_liquidityWallet",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_blAddress",
				"type": "address"
			}
		],
		"name": "removeBlacklistAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_burningTax",
				"type": "uint256"
			}
		],
		"name": "setBurningTax",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_liquidityTax",
				"type": "uint256"
			}
		],
		"name": "setLiquidityTax",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_liquidityWallet",
				"type": "address"
			}
		],
		"name": "setLiquidityWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_devTax",
				"type": "uint256"
			}
		],
		"name": "setReflection",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "blacklisted",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burnAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burningTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DECIMALS",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHolders",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "liquidityTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "liquidityWallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "reflection",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "routerAddress",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "taxExemptedAddresses",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TOTAL_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
let addressToken = "0xfb74Fd8B4C93C328f1a4C53C178866ba0bECbCF1"

let ABI =[
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_tokenWeth",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_rate",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_startTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_endTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_totalTokensForSale",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_targetFunds",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_initialOwner",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "buyer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "TokensPurchased",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "UnsoldTokensWithdrawn",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdrawn",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "buyTokens",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokensFromWeth",
				"type": "uint256"
			}
		],
		"name": "buyTokensFromWeth",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrector",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fundsForwardingAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getProgress",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "collectedFunds",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalTargetFunds",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrector",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrector",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_endTime",
				"type": "uint256"
			}
		],
		"name": "setEndTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_fundsForwardingAddress",
				"type": "address"
			}
		],
		"name": "setFundsForwardingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rate",
				"type": "uint256"
			}
		],
		"name": "setRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_startTime",
				"type": "uint256"
			}
		],
		"name": "setStartTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_targetFunds",
				"type": "uint256"
			}
		],
		"name": "setTargetFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wethContract",
				"type": "address"
			}
		],
		"name": "setTokenWeth",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_totalTokensForSale",
				"type": "uint256"
			}
		],
		"name": "setTotalTokensForSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "targetFunds",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWeth",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalFundsCollected",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTokensForSale",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTokensSold",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawUnsoldTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let address = "0x8c7bf86830cBdA89cd5527BeE49D552B26036645"

let ABIWETH = [
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "src",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "guy",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "dst",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "Deposit",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "src",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "dst",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "src",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "Withdrawal",
	  "type": "event"
	},
	{
	  "payable": true,
	  "stateMutability": "payable",
	  "type": "fallback"
	},
	{
	  "constant": true,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "allowance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "guy",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "decimals",
	  "outputs": [
		{
		  "internalType": "uint8",
		  "name": "",
		  "type": "uint8"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [],
	  "name": "deposit",
	  "outputs": [],
	  "payable": true,
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "dst",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "transfer",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "src",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "dst",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "wad",
		  "type": "uint256"
		}
	  ],
	  "name": "withdraw",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	}
  ];
let addressWETH = "0x4200000000000000000000000000000000000006"

const Presale = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState('0%')

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_addresses, set_addresses] = useState([]);
	const [_commission, setcommission] = useState(0);
	const [_leaderboardArray, setLeaderboardArray] = useState([]);
	const [commissionData, setSortedCommissionData] = useState([]);
	const [_commissionsArray, setCommissionsArray] = useState([]);
	const [_commissionsReceivedFromH, setCommissionsReceivedFromH] = useState([]);
	const [_totalCommissions, setTotalCommissions] = useState(0);
	const [_amount, set_amount] = useState('');
	const websocketUrl = "wss://go.getblock.io/b874e8b4021e4f52907c309725d10b2d";
	const httpURL = "https://go.getblock.io/139e814351f54f169e78cf8776eb0b1e"
	const [selectedCurrency, setSelectedCurrency] = useState('ETH');
	const [_increaseAllowanceAmount, setIncreaseAllowanceAmount] = useState(0);
	const [_currentAllowance, setCurrentAllowance] = useState(0);
	const [_quantity, set_quantity] = useState(0);
	const [_allowanceStatus, setAllowanceStatus] = useState(false);

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	//const transport = webSocket(websocketUrl);
	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: base,
		transport
	})

	async function closeNav() {
		set_navbarOpen(0);
	}

	async function navbarOpen() {
		set_navbarOpen(1);
	}

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	var contract3 = {
		address: addressWETH,
		abi: ABIWETH
	}

	const [_totalSupply, settotalSupply] = useState(0);
	const [statusError, setstatusError] = useState(false);
	const [statusLoading, setstatusLoading] = useState(false);
	const [_owner, set_owner] = useState("");
	const [myNFTWallet, setmyNFTWallet] = useState(0);
	const [_public_mint_status, set_public_mint_status] = useState("");
	const [_connected, setConnected] = useState(false);
	const [_registerDiv, set_registerDiv] = useState(0);
	const [_dashboardDiv, set_dashboardDiv] = useState(0);
	const [_name, set_name] = useState("");
	const { disconnect } = useDisconnect();
	const [_success1, setSuccessMsg1] = useState("");
	const [_loading1, setLoadingMsg1] = useState("");
	const [_ErrorMsg1, setErrorMsg1] = useState("");
	const [success, setsuccess] = useState(false);
	const [nfts, setNfts] = useState([]);
	const [selectedContractIndex, setSelectedContractIndex] = useState(0);
	const [userInfo, setUserInfo] = useState(null);
	const [userNFTs, setUserNFTs] = useState([]);
	const [nft1Collection, setUserInfoForNFT1] = useState([1, 2, 3]);
	const [nft2Collection, setUserInfoForNFT2] = useState([1, 2]);
	const [nft3Collection, setUserInfoForNFT3] = useState([1, 2, 3]);
	const [_viewUser, set_getUser] = useState("");
	const [_totalUsers, set_totalUsers] = useState("");
	const [_getAddressById, set_getAddressById] = useState("");

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [txnHash, setTxnHash] = useState('');
	const [_totalTokensSold, setTotalTokensSold] = useState(0);
	const [_totalTokensForSale, setTotalTokensForSale] = useState(0);
	const [_targetFunds, setTargetFunds] = useState(0);
	const [_totalFundsCollected, setTotalFundsCollected] = useState(0);
	const [_rate, setRate] = useState(0);

	async function handleConnect() {
		if (chain.id !== 8453) {
			switchNetwork(8453);
		}

		//await fetchNFTs();
		setConnected(true);
	}

	function formatNumber(number) {
		// Convert number to string and split into integer and decimal parts
		let [integerPart, decimalPart] = number.toString().split('.');

		// Add commas every three digits from the right for the integer part
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		// Combine the integer part with the decimal part if it exists
		return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
	}


	useEffect(() => {

		async function fetchData() {
			var data = await getTotalTokensSold();
			let totalTokensSold = Number(data.data);
			setTotalTokensSold(totalTokensSold);
			console.log("TotalTokensSold : " + totalTokensSold);

			var data2 = await getTotalTokensForSale();
			let totalTokensForSale = Number(data2.data);
			setTotalTokensForSale(totalTokensForSale);
			console.log("totalTokensForSale : " + totalTokensForSale);

			// Calculate the percentage
			let percentage = (Number(totalTokensSold) / Number(totalTokensForSale)) * 100;
			setPercentage(percentage.toFixed(2) + '%');
			console.log("percentage : " + percentage);
		}

		async function fetchData2_2() {
			var data = await getTotalFundsCollected();
			let totalFundsCollected = Number(data.data);
			setTotalFundsCollected(totalFundsCollected);
			console.log("totalFundsCollected : " + totalFundsCollected);
		}

		async function fetchData2() {
			var data = await getRate();
			let rate = Number(data.data);
			setRate(rate);
			console.log("rate : " + rate);
		}

		async function fetchData3() {
			var data = await getTargetFunds();
			let targetFunds = Number(data.data);
			setTargetFunds(targetFunds);
			console.log("targetFunds : " + targetFunds);
		}

		async function fetchData4() {
 			const data = await getAllowance();
			setCurrentAllowance(Number(data.data));
			console.log("Allowance for the presale contract ETH : " + Number(data.data));


			if(_amount * 10 ** 18 < Number(data.data)){
				setAllowanceStatus(true);
				console.log("allowanceStatus : " + "true");
			}else{
				console.log("allowanceStatus : " + "false");
				console.log("allowanceStatus : " + _allowanceStatus);

			}		
		}

		fetchData();
		fetchData2();
		fetchData2_2();
		fetchData3();

		console.log("_percentage : " + _percentage);
		if (_connected) {
			fetchData4();
		}

	}, [_connected, walletAddress, _percentage]);

	async function allowance() {

		try {
			console.log("_quantity : " + _amount);
			console.log("_currentAllowance : " + _currentAllowance);

			//const increaseAllowanceAmount = Number(_getRepayAmount) - Number(_currentAllowance);

			setIncreaseAllowanceAmount((Number(_amount * 10 ** 18) - Number(_currentAllowance)));
			console.log("data.data : " + _currentAllowance);

			const _increaseAllowance = ((Number(_amount * 10 ** 18) - Number(_currentAllowance)));
			console.log("Amount increasing :" + _increaseAllowance);
			const rounded = Math.round(_increaseAllowance);


			var res = await writeAsync({
				...contract3,
				functionName: 'increaseAllowance',
				args: [address, rounded],

				//value: '100000000000000000',
				gasLimit: '685000'
			})
			var result = await publicClient.waitForTransactionReceipt(res)

			if (result.status === 'success') {
 
 				window.location.reload();
			}
			else {

			}

		} catch (err) {
 			console.log(err);
		}

	}

	const { refetch: getAllowance } = useContractRead({
		...contract3,
		functionName: 'allowance', args: [walletAddress, address]
	})

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
	}

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	async function buyTokens() {
		try {
			// Convert the amount entered by the user to a BigNumber in wei
			const amountInWei = parseUnits(_amount, 'ether');
			console.log("amountInWei" + amountInWei);

			setstatusLoading(true);
			const res = await writeAsync({
				...contract,
				functionName: 'buyTokens',
				gasLimit: '685000', // Adjust gas limit as needed
				value: amountInWei.toString(), // Send the amount of Ether in wei with the transaction
			});

			const txnHash = res.hash;
			setTxnHash(txnHash);

			await new Promise(resolve => setTimeout(resolve, 3000));

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				const successMsg = "Transaction Successful!";
				const loadMsg = "";
				setSuccessMsg1(successMsg);
				setLoadingMsg1(loadMsg);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (e) {
			//console.log("message" + e.message)

			//const details = e.message.split('\n').filter(line => !line.includes('Docs:')).join('\n').trim();

			//console.log("Details: ", details);

			if (e.message.includes("err: insufficient funds for gas")) {
				const errorName = "Insufficient funds";
				setErrorMsg1(errorName);
				console.log("error : " + errorName);
				console.log("error msg :" + _ErrorMsg1)


			} else if (e.message.includes("User rejected the request")) {
				const errorName = "User Rejected";
				setErrorMsg1(errorName);
				console.log("error2 : " + errorName);
				console.log("error msg :" + _ErrorMsg1)

			} else {
				const errorName = "Sorry, something went wrong";
				setErrorMsg1(errorName);
				console.log("error3 : " + errorName);
				console.log("error msg :" + _ErrorMsg1)

			}


			setstatusError(true)
			setstatusLoading(false)

		}
	}

	async function buyTokens2() {
		try {
			// Convert the amount entered by the user to a BigNumber in wei
			const amountInWei = parseUnits(_amount, 'ether');
			console.log("amountInWei" + amountInWei);

			setstatusLoading(true);
			const res = await writeAsync({
				...contract,
				functionName: 'buyTokensFromWeth',
				args: [amountInWei.toString()],
				gasLimit: '685000', // Adjust gas limit as needed
			});

			const txnHash = res.hash;
			setTxnHash(txnHash);

			await new Promise(resolve => setTimeout(resolve, 3000));

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				const successMsg = "Transaction Successful!";
				const loadMsg = "";
				setSuccessMsg1(successMsg);
				setLoadingMsg1(loadMsg);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (e) {
			//console.log("message" + e.message)

			//const details = e.message.split('\n').filter(line => !line.includes('Docs:')).join('\n').trim();

			//console.log("Details: ", details);

			if (e.message.includes("err: insufficient funds for gas")) {
				const errorName = "Insufficient funds";
				setErrorMsg1(errorName);
				console.log("error : " + errorName);
				console.log("error msg :" + _ErrorMsg1)


			} else if (e.message.includes("User rejected the request")) {
				const errorName = "User Rejected";
				setErrorMsg1(errorName);
				console.log("error2 : " + errorName);
				console.log("error msg :" + _ErrorMsg1)

			} else {
				const errorName = "Sorry, something went wrong";
				setErrorMsg1(errorName);
				console.log("error3 : " + errorName);
				console.log("error msg :" + _ErrorMsg1)

			}


			setstatusError(true)
			setstatusLoading(false)

		}
	}


	const { refetch: getTotalTokensSold } = useContractRead({
		...contract,
		functionName: 'totalTokensSold', args: [],
	})

	const { refetch: getTotalFundsCollected } = useContractRead({
		...contract,
		functionName: 'totalFundsCollected', args: [],
	})

	const { refetch: getTargetFunds } = useContractRead({
		...contract,
		functionName: 'targetFunds', args: [],
	})

	const { refetch: getTotalTokensForSale } = useContractRead({
		...contract,
		functionName: 'totalTokensForSale', args: [],
	})

	const { refetch: getRate } = useContractRead({
		...contract,
		functionName: 'rate', args: [],
	})

	async function closeWindow2() {
		setstatusError(false);
	}

	async function closeWindow3() {
		window.location.reload();
	}

	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont2">
					{_navbarOpen < 1 ?
						(<div class="miniBarMain">

							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">

							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					<div class="headers">

						<div class="h1">

							<div class="connect">

								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
									<img onClick={() => window.location.href = '/'} src={home} />
								</div>

							</div>

							<div class="logoDiv"><img src={logoPic} onClick={() => window.location.href = '/'}></img></div>
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>
							<div class="right">

								<div class="connect2">
									<Web3Button />
								</div>

							</div>

						</div>

					</div>

					<Link to="gallery" spy={true} smooth={true} duration={550}>


						{_navbarOpen > 0 ?
							(<div class="littleNav">
								<div id="fontSize" onClick={() => window.location.href = '/'}>HOME</div>
								<div id="fontSize">
									<Web3Button />
								</div>
								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
									<img onClick={() => window.location.href = '/'} src={home} />
								</div>

							</div>) : null}
					</Link>

					<div class="presale">
						<div class="container">
							<h1 class="title">Token Presale</h1>
							<p class="description">Join our exclusive presale and be a part of the future!</p>
							<div class="progress-bar">
								<div class="progress" style={{ width: _percentage }}><span className='pText'>{_percentage}</span></div>
							</div>
							<div class="stats">
								<div class="stat">
									<h2>Sale Statistics</h2>
									<p>
										<div><span>Total Funds Collected - {formatNumber(_totalFundsCollected / 10 ** 18)} ETH</span></div>
									</p>
								</div>

								<div class="stat">
									<h2>Presale Statistics</h2>
									<p>
										<div><span>Target Funds - {formatNumber(_targetFunds)} ETH</span></div>
									</p>
								</div>
							</div>

							<form className="purchase-form" onSubmit={e => e.preventDefault()}>
								<div className='amount'>{_amount * _rate} $BAM</div>

								<div className='inputSection'>
									<input
										placeholder="Number of tokens"
										required
										name="amounts"
										type='number'
										value={_amount}
										onChange={event => set_amount(event.target.value)}
									/>
									<select
										value={selectedCurrency}
										onChange={event => setSelectedCurrency(event.target.value)}
										className='Receive'
									>
										<option value="ETH">ETH</option>
										<option value="wETH">wETH</option>
									</select>
								</div>

								{_connected ? (
									selectedCurrency === 'ETH' ? (
										<button onClick={buyTokens}>Buy Now</button>
									) : (
										_allowanceStatus ?
											(<button onClick={buyTokens2}>Buy Now</button>) :
											(<button onClick={allowance}>Approve</button>)

									)
								) : (
									<button onClick={() => { open(); }}>Connect</button>
								)}
							</form>


						</div>
					</div>

				</div>

				<Ticker3 />

				<Footer />

			</div >

			<div>

				{statusError ?
					<div class="formMain2">
						<button class="closeNote" onClick={closeWindow2}>✖</button>
						<div class="formMain-2">
							<div className='center'>
								<img class="check" src={balloon} />
								<div class="errorMessage">
									{_ErrorMsg1.toString()}
								</div>
								<p></p>

							</div>
						</div>

					</div> : null}

				{statusLoading ?
					(<div class="formMain2">
						<div class="formMain-2">
							<div className='center'>
								<div class="loader"></div>
								<div id="loadingT" class="errorMessage">
									Please wait while the transaction is being processed
								</div>
								<p></p>
							</div>
						</div>

					</div>) : null}

				{success ?
					(<div class="formMain2">
						<button class="closeNote" onClick={closeWindow3}>✖</button>
						<div class="formMain-2">
							<div className='center'>
								<img class="check" src={check} />

								<div class="success-message">
									<h2><span>Congratulations</span></h2>
									<h2>{_success1.toString()}</h2>
									<div className='viewTxn'><a href={`https://basescan.org/tx/${txnHash}`} target="_blank" rel="noopener noreferrer">View Transaction</a></div>
								</div>
							</div>
						</div>


					</div>) : null}

			</div>
		</div >
	)

}
export default Presale;
